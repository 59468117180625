/* tslint:disable */
/* eslint-disable */
/**
 * Ethos Partners API Reference
 * Documentation of Ethos Partners API.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SignupChannelEnum {
  SelfOnboarding = 'self onboarding',
  Empty = '',
}

export function SignupChannelEnumFromJSON(json: any): SignupChannelEnum {
  return SignupChannelEnumFromJSONTyped(json, false)
}

export function SignupChannelEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SignupChannelEnum {
  return json as SignupChannelEnum
}

export function SignupChannelEnumToJSON(value?: SignupChannelEnum | null): any {
  return value as any
}
