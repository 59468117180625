export const PARTNER_SIGNUP_DATA_TID = {
  AGENCY_NAME: 'partner-signup-agency-name-input',
  EMAIL: 'partner-signup-email-input',
  CONFIRM_EMAIL: 'partner-signup-email-reenter',
  FIRST_NAME: 'partner-signup-firstname-input',
  LAST_NAME: 'partner-signup-lastname-input',
  PHONE: 'partner-signup-phone',
  PRODUCER_COUNT: 'partner-signup-producer-count-input',
  POLICY_COUNT: 'partner-signup-policy-count-input',
  ROLE: 'partner-signup-role-input',
  LICENSE: 'partner-signup-license-input',
  STATE: 'partner-signup-state-input',
  SUBMIT_BUTTON: 'partner-signup-submit-button',
  FORM: 'partner-signup-form',
  AGREE_TERMS: 'partner-signup-agree-terms-input',
  AUTHORISATION: 'partner-signup-authorisation',
  REFERRER: 'partner-signup-referrer',
}

export const phoneNumberMask = [
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const protocol = (url: string): string =>
  url.includes('local') ? 'http://' : 'https://'
