/* tslint:disable */
/* eslint-disable */
/**
 * Ethos Partners API Reference
 * Documentation of Ethos Partners API.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetSignupInfoResponsePartner,
  GetSignupInfoResponsePartnerFromJSON,
  GetSignupInfoResponsePartnerFromJSONTyped,
  GetSignupInfoResponsePartnerToJSON,
} from './GetSignupInfoResponsePartner'

/**
 *
 * @export
 * @interface GetSignupInfoResponse
 */
export interface GetSignupInfoResponse {
  /**
   *
   * @type {GetSignupInfoResponsePartner}
   * @memberof GetSignupInfoResponse
   */
  partner: GetSignupInfoResponsePartner
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponse
   */
  token: string
}

/**
 * Check if a given object implements the GetSignupInfoResponse interface.
 */
export function instanceOfGetSignupInfoResponse(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'partner' in value
  isInstance = isInstance && 'token' in value

  return isInstance
}

export function GetSignupInfoResponseFromJSON(
  json: any
): GetSignupInfoResponse {
  return GetSignupInfoResponseFromJSONTyped(json, false)
}

export function GetSignupInfoResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSignupInfoResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    partner: GetSignupInfoResponsePartnerFromJSON(json['partner']),
    token: json['token'],
  }
}

export function GetSignupInfoResponseToJSON(
  value?: GetSignupInfoResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    partner: GetSignupInfoResponsePartnerToJSON(value.partner),
    token: value.token,
  }
}
