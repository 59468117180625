/* tslint:disable */
/* eslint-disable */
/**
 * Ethos Partners API Reference
 * Documentation of Ethos Partners API.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from './PartnerData'

/**
 *
 * @export
 * @interface GetSignupInfoResponsePartnerContact
 */
export interface GetSignupInfoResponsePartnerContact {
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartnerContact
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartnerContact
   */
  lastName?: string
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartnerContact
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartnerContact
   */
  phoneNumber: string
}

/**
 * Check if a given object implements the GetSignupInfoResponsePartnerContact interface.
 */
export function instanceOfGetSignupInfoResponsePartnerContact(
  value: object
): boolean {
  let isInstance = true
  isInstance = isInstance && 'phoneNumber' in value

  return isInstance
}

export function GetSignupInfoResponsePartnerContactFromJSON(
  json: any
): GetSignupInfoResponsePartnerContact {
  return GetSignupInfoResponsePartnerContactFromJSONTyped(json, false)
}

export function GetSignupInfoResponsePartnerContactFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSignupInfoResponsePartnerContact {
  if (json === undefined || json === null) {
    return json
  }
  return {
    firstName: !exists(json, 'first_name') ? undefined : json['first_name'],
    lastName: !exists(json, 'last_name') ? undefined : json['last_name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phoneNumber: json['phone_number'],
  }
}

export function GetSignupInfoResponsePartnerContactToJSON(
  value?: GetSignupInfoResponsePartnerContact | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    first_name: value.firstName,
    last_name: value.lastName,
    email: value.email,
    phone_number: value.phoneNumber,
  }
}
