import React from 'react'

import {
  COLORS,
  TitleLarge2,
  TitleMedium2,
  TitleXLarge2,
} from 'ethos-design-system'

export const H1Responsive = ({
  color,
  copy,
  className,
}: {
  color?: COLORS
  copy: string
  className?: string
}): JSX.Element => (
  <div className={className}>
    <div className="sm:block md:hidden">
      <TitleMedium2.Serif.Book500 color={color} element="h1">
        {copy}
      </TitleMedium2.Serif.Book500>
    </div>
    <div className="hidden md:block lg:hidden">
      <TitleLarge2.Serif.Book500 color={color} element="h1">
        {copy}
      </TitleLarge2.Serif.Book500>
    </div>
    <div className="hidden lg:block">
      <TitleXLarge2.Serif.Book500 color={color} element="h1">
        {copy}
      </TitleXLarge2.Serif.Book500>
    </div>
  </div>
)
