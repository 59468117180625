/**
 * based on values configured through Recaptcha admin
 * https://console.cloud.google.com/security/recaptcha?project=ethos-176119
 */
export const RECAPTCHA_FULLSTACK = {
  development: '6Ley_goeAAAAAOgKW9PncGkjWV_YeK1TiE_ELMft',
  test: '6Ley_goeAAAAAOgKW9PncGkjWV_YeK1TiE_ELMft',
  production: '6LfD3g4eAAAAAN1y75pOSOB46W4PEhvvuaU225kY',
}

export const getRecaptchaKey = ({
  env = process.env.NEXT_PUBLIC_ETHOS_ENV,
} = {}): string => {
  return env === 'localhost' || !env
    ? RECAPTCHA_FULLSTACK.development
    : env === 'staging'
    ? RECAPTCHA_FULLSTACK.test
    : RECAPTCHA_FULLSTACK.production
}
