import React, { useState } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { CloudinaryImage } from 'ethos-design-system'
import get from 'lodash.get'

import useSiteAgentsApiUrl from '@/hooks/useSiteAgentsApiUrl'
import useSiteAgentsUrl from '@/hooks/useSiteAgentsUrl'
import useSiteExternalGatewayApiUrl from '@/hooks/useSiteExternalGatewayApiUrl'

import { getQueryString } from '../../../lib/@getethos/utils/utils'
import { AgentFormManager } from './AgentForm/AgentFormManager'
import styles from './PartnerSignup.module.scss'
import { H1Responsive } from './common/H1Responsive'
import { Subheading } from './common/Subheading'
import { useGrecaptcha } from './lib/useGrecaptcha'
import { useSignupInfo } from './lib/useSignupInfo'
import { useSignupParams } from './lib/useSignupParams'

dayjs.extend(utc)

const PartnerSignup = ({ moduleData }: { moduleData: any }): JSX.Element => {
  const CapitalizedBackgroundColor =
    moduleData.backgroundColor.charAt(0).toUpperCase() +
    moduleData.backgroundColor.slice(1)

  const SectionInnerClasses = [
    'floatingContainer relative w-full',
    styles[`bg${CapitalizedBackgroundColor}`],
  ]

  const mediumSectionHeight = 480

  const backgroundImageWrapper =
    'z-0 hidden md:block lg:max-h-80 absolute bottom-0 left-0 right-0 overflow-hidden'
  const backgroundImage = ''

  const heightArray = [0, 256, mediumSectionHeight, 640]
  const widthArray = [0, 768, 1440, 1920]

  const container =
    'z-1 w-full max-w-screen-md-plus mx-auto px-3 py-8 md:px-12 lg:p-0 flex flex-wrap md:flex-nowrap justify-center md:justify-between'

  const headings = 'z-1 md:mt-16 lg:mr-16 pb-6 text-center md:text-left'
  const h1 = classNames(
    'max-w-md md:max-w-lg-plus pb-6',
    moduleData.formType === 'gfiSignup' && 'text-white'
  )
  const h2 = classNames(
    'max-w-xs md:w-72 lg:w-96 mx-auto md:mx-0',
    moduleData.formType === 'gfiSignup' && 'text-white'
  )

  const { signupForm, signupType } = useSignupParams()
  const siteAgentsUrl = useSiteAgentsUrl()
  const siteAgentsApiUrl = useSiteAgentsApiUrl()
  const siteExtneralGatewayApiUrl = useSiteExternalGatewayApiUrl()

  const referrerCode = get(getQueryString(), 'r')
  const parentCode = get(getQueryString(), 'p')

  const referrer = useSignupInfo(siteExtneralGatewayApiUrl, referrerCode)
  const parent = useSignupInfo(siteExtneralGatewayApiUrl, parentCode)

  const grecaptcha = useGrecaptcha()

  const [error, setError] = useState('')

  return (
    <section className={SectionInnerClasses.join(' ')}>
      <div className={container}>
        <div className={headings}>
          <H1Responsive className={h1} copy={moduleData.heading} />

          <Subheading
            className={h2}
            displaySubHeading={moduleData.displaySubHeading}
          >
            {moduleData.subHeading}
          </Subheading>
        </div>

        <AgentFormManager
          formType={moduleData.formType}
          signupType={signupType}
          signupForm={signupForm}
          parent={parent}
          referrer={referrer}
          grecaptcha={grecaptcha}
          siteAgentsUrl={siteAgentsUrl}
          siteAgentsApiUrl={siteAgentsApiUrl}
          setError={setError}
          error={error}
          continueLabel={moduleData.continueLabel}
        />
      </div>

      {moduleData.groupImage && moduleData.imageAlt && (
        <div className={backgroundImageWrapper}>
          <CloudinaryImage
            publicId={moduleData.groupImage}
            alt={moduleData.imageAlt}
            className={backgroundImage}
            crop={CloudinaryImage.CROP_METHODS.FILL}
            height={heightArray}
            width={widthArray}
          />
        </div>
      )}
    </section>
  )
}

export default PartnerSignup
