import React, { useState } from 'react'

import Image from 'next/image'

import classNames from 'classnames'
import { Body2, Button, Form, Spacer } from 'ethos-design-system'

import { PartnerInfo } from '../lib/useSignupInfo'
import styles from './AgentForm.module.scss'
import { PARTNER_SIGNUP_DATA_TID, protocol } from './consts'

const bulbImage = require('../../../img/bulb.svg') as string

interface AgentFormPropsModule {
  parent: PartnerInfo | undefined
  referrer: PartnerInfo | undefined
  siteAgentsUrl: string
  handleSubmit: (formData: any) => Promise<void>
  fields: any
  error: string
  continueLabel: string
  formType: string
}

const formClasses = ['max-w-sm p-6 bg-white rounded-lg', styles.form]
const referrerClasses = [
  'border-solid border font-sans font-medium body-2 text-salamander bg-buttercup rounded-[32px] p-1',
  styles.referrer,
]

const replaceErrorMessage = (error: string) => {
  const errorMessages = [
    'This phone number is already registered with Ethos',
    'This email is already registered with Ethos',
  ]
  const formUrl = 'https://forms.gle/KpZ2MtmfJ4o5ebeb7'

  if (errorMessages.some((msg) => error.includes(msg))) {
    return (
      <>
        We’re having some difficulty signing you up. Please{' '}
        <a
          href={formUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          fill out this form
        </a>{' '}
        and someone will get back to you within 48-72 hours.
      </>
    )
  }
  return error
}

const AgentForm: React.FunctionComponent<AgentFormPropsModule> = ({
  formType,
  parent,
  referrer,
  siteAgentsUrl,
  handleSubmit,
  fields,
  error,
  continueLabel,
}) => {
  const [emailError, setEmailError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isFormGFI = formType === 'gfiSignup'
  const validateEmails = (email: string, confirmEmail: string) => {
    if (isFormGFI) {
      if (email !== confirmEmail) {
        setEmailError('Email address not matching')
        return false
      }
      setEmailError(null)
    }
    return true
  }

  return (
    <Form
      config={{
        formName: 'Agent Form',
        formId: 'AgentForm',
        fields: fields,
        onSubmit: async (formData: { email: string; confirmEmail: string }) => {
          const { email, confirmEmail } = formData
          const isEmailValid = validateEmails(email, confirmEmail)
          if (isEmailValid) {
            setIsLoading(true)
            await handleSubmit(formData)
            setIsLoading(false)
          }
        },
      }}
    >
      {/* @ts-ignore */}
      {({ field, getFormIsValid, getFieldValues }) => {
        return (
          <div
            className={classNames(
              styles.formWrapper,
              isFormGFI ? styles.gfiForm : 'tablet:h-[700px]'
            )}
          >
            <div className={formClasses.join(' ')}>
              {parent && (
                <div className={referrerClasses.join(' ')}>
                  <Image src={bulbImage} className="p-1" alt="alert" />
                  <div className={styles.flexStretch}>
                    {' '}
                    Added by: {parent.partnerName}{' '}
                  </div>
                </div>
              )}

              {referrer && (
                <div className={referrerClasses.join(' ')}>
                  <Image src={bulbImage} className="p-1" alt="alert" />
                  <div className={styles.flexStretch}>
                    {' '}
                    Referred by: {referrer.partnerName}{' '}
                  </div>
                </div>
              )}
              {isFormGFI && (
                <div className="Cambon mb-6 text-[32px] font-medium text-gray-5">
                  Agent registration
                </div>
              )}
              <Spacer.H4 />
              {!isFormGFI && (
                <>
                  {field('agencyName')}
                  <Spacer.H24 />
                </>
              )}
              <div className={styles.nameContainer}>
                <div className={styles.name}>{field('firstName')}</div>
                <Spacer.W16 />
                <div className={styles.name}>{field('lastName')}</div>
              </div>
              <Spacer.H24 />
              {field('email')}
              <Spacer.H24 />
              {isFormGFI && (
                <>
                  {field('confirmEmail')}
                  {emailError && (
                    <>
                      <Spacer.H8 />
                      <div className={styles.error}>{emailError}</div>
                    </>
                  )}
                  <Spacer.H24 />
                </>
              )}
              {field('phone')}
              <Spacer.H24 />
              {isFormGFI && (
                <>
                  {field('referrer')}
                  <Spacer.H24 />
                  {field('authorisation')}
                  <Spacer.H24 />
                </>
              )}
              {field('agreeTerms')}
              {isFormGFI ? <Spacer.H8 /> : <Spacer.H24 />}
              {error && (
                <div className="mb-4 bg-duckegg p-3 text-sm">
                  {replaceErrorMessage(error)}
                </div>
              )}
              {isFormGFI ? <Spacer.H8 /> : <Spacer.H16 />}

              {isFormGFI && getFieldValues().authorisation === 'no' && (
                <div className="mb-4 bg-duckegg p-3 text-sm">
                  Only individuals authorized to work in the USA are allowed to
                  register with GFI.
                  <br />
                  Please contact support@mygficonnect.com.
                </div>
              )}

              <div
                className={isFormGFI ? styles.gfiFullWidth : styles.fullWidth}
              >
                <Button.Medium.Black
                  disabled={!getFormIsValid() || isLoading}
                  data-tid={PARTNER_SIGNUP_DATA_TID.SUBMIT_BUTTON}
                  type="submit"
                >
                  {isLoading ? 'Submitting...' : continueLabel}
                </Button.Medium.Black>
              </div>
              <Spacer.H24 />
              <div className={isFormGFI ? '' : 'text-center'}>
                <Body2.Regular400>
                  <span
                    className={
                      isFormGFI ? 'text-sm text-forest' : 'text-forest'
                    }
                  >
                    <span className="text-gray-5">
                      Already have an account?&nbsp;
                    </span>
                    <a
                      href={`${protocol(siteAgentsUrl) + siteAgentsUrl}/login`}
                    >
                      {' '}
                      Sign In here
                    </a>
                  </span>
                </Body2.Regular400>
              </div>
            </div>
          </div>
        )
      }}
    </Form>
  )
}

export default AgentForm
