//@ts-nocheck
import { useMemo } from 'react'

import { useGlobalScript } from './globalScript'
import { getRecaptchaKey } from './useRecaptchaKey'

const RECAPTCHA_KEY: string = getRecaptchaKey()

export function useGrecaptcha(): Promise<typeof grecaptcha> {
  const scriptPromise = useGlobalScript(
    `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`
  )

  return useMemo<Promise<typeof grecaptcha>>(async () => {
    try {
      await scriptPromise

      if (typeof window !== 'undefined' && window.grecaptcha) {
        return window.grecaptcha
      }

      throw new Error('reCAPTCHA library not found')
    } catch (error) {
      //throw new Error('Failed to load reCAPTCHA script'); //TODO: uncomment
    }
  }, [scriptPromise])
}

type grecaptchaType = typeof grecaptcha

export async function getGrecaptchaToken(
  grecaptchaReadyPromise: Promise<grecaptchaType>
): Promise<string> {
  const grecaptcha = await grecaptchaReadyPromise
  return grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' })
}
