import { useEffect, useState } from 'react'

import { requestGetSignupInfo } from '../../../../lib/@getethos/api/api'
import {
  GetSignupInfoResponse,
  GetSignupInfoResponseFromJSON,
} from '../../../../lib/@getethos/interfaces/GetSignupInfoResponse'

export type PartnerInfo = {
  partnerName: string
  partnerCode: string
  partnerId: number
}

export function useSignupInfo(
  siteExtneralGatewayApiUrl: string,
  partnerCode: string | undefined
): PartnerInfo | undefined {
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo | undefined>()

  useEffect(() => {
    const getPartnerName = (signupInfo: GetSignupInfoResponse): string => {
      if (!signupInfo.partner || !signupInfo.partner.contact) {
        return ''
      }

      //for self-sign on agent, their agency name would be from the parent 'Ethos'
      //thus we need replace it with the agent's full name
      return signupInfo.partner.agencyName == 'Ethos'
        ? `${signupInfo.partner.contact.firstName || ''} ${
            signupInfo.partner.contact.lastName || ''
          }`
        : signupInfo.partner.agencyName || ''
    }

    const requestSignupInfo = async (code: string): Promise<void> => {
      try {
        const response = await requestGetSignupInfo(
          code,
          siteExtneralGatewayApiUrl
        )

        const signupInfo = GetSignupInfoResponseFromJSON(response)
        setPartnerInfo({
          partnerName: getPartnerName(signupInfo),
          partnerCode: signupInfo.partner.code,
          partnerId: signupInfo.partner.id,
        })
      } catch (err) {
        console.warn(err)
      }
    }

    if (partnerCode) {
      requestSignupInfo(partnerCode)
    }
  }, [partnerCode, siteExtneralGatewayApiUrl])

  return partnerInfo
}
