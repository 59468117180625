//@ts-nocheck
import { useMemo } from 'react'

import get from 'lodash.get'

import {
  PartnerDBEnum,
  SignupForm,
  SignupType,
} from '../../../../lib/@getethos/interfaces/PartnerData'
import { getQueryString } from '../../../../lib/@getethos/utils/utils'

export function useSignupParams(): {
  partnerType: PartnerDBEnum
  signupForm: SignupForm
  signupType: SignupType
} {
  const partnerTypeParam = get(getQueryString(), 'partnerType')
  const partnerCodeParam = get(getQueryString(), 'code')

  const partnerType = useMemo(() => {
    switch (partnerTypeParam) {
      case 'network':
        return partnerCodeParam ? PartnerDBEnum.Agency : PartnerDBEnum.Network
      case 'agency':
      case 'enterprise_agency':
        return PartnerDBEnum.Agency
      default:
        return PartnerDBEnum.Agent
    }
  }, [partnerCodeParam, partnerTypeParam])

  const signupForm = useMemo(() => {
    switch (partnerTypeParam) {
      case 'network':
        return SignupForm.Network
      case 'agent':
        return SignupForm.Agent
      default:
        return SignupForm.Agency
    }
  }, [partnerTypeParam])

  const signupType = useMemo(() => {
    switch (partnerTypeParam) {
      case 'enterprise_agency':
        return SignupType.Enterprise
      default:
        return SignupType.SMB
    }
  }, [partnerTypeParam])

  return {
    partnerType,
    signupForm,
    signupType,
  }
}
