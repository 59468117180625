//@ts-nocheck
import React, { useState } from 'react'

import {
  CheckboxInput,
  EmailInput,
  NumberInput,
  RadioButtonGroup,
  TextInput,
  Tooltip,
} from 'ethos-design-system'
import EmailFormatValidator from 'ethos-design-system/src/validators/EmailValidator'

import { partnerSignupModuleAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import { InfoIcon } from '../../../icons'
import styles from './AgentForm.module.scss'
import { PARTNER_SIGNUP_DATA_TID, phoneNumberMask } from './consts'

const validateValueExists = (x): string =>
  typeof x !== 'undefined' && x !== '' ? '' : 'Please provide a value'

const validateFirstName = (x): string =>
  typeof x !== 'undefined' && x !== '' ? '' : 'Please enter first name'

const validateLastName = (x): string =>
  typeof x !== 'undefined' && x !== '' ? '' : 'Please enter last name'

const validateReferrerCode = (x): string =>
  typeof x !== 'undefined' && x !== '' ? '' : 'Please enter referrer code'

const validateAgencyName = (x): string =>
  typeof x !== 'undefined' && x !== ''
    ? ''
    : "Please enter your name if you're not with an agency"

const validatePhoneNumber = (x): string =>
  typeof x !== 'undefined' && x.match(/\([2-9]\d\d\) \d\d\d-\d\d\d\d/)
    ? ''
    : 'Please enter a valid phone number'

const validateChecked = (x): string => {
  return typeof x !== 'undefined' && x == true
    ? ''
    : 'Must read and agree to terms and conditions'
}

const validateRadioSelected = (x): string => {
  return typeof x !== 'undefined' ? '' : 'Must select to sign up'
}

const validateAuthorized = (x): string => {
  return typeof x !== 'undefined' && x == 'yes'
    ? ''
    : 'Sorry, must be authorized'
}

const TextField = (props: any, options: any = {}): JSX.Element => {
  return (
    <TextInput
      name={options.name}
      placeholder={options.placeholder}
      allCaps={options.allCaps}
      labelWeight={options.labelWeight}
      {...props}
    />
  )
}

const TextFieldReferrerCode = (props: any, options: any = {}): JSX.Element => {
  return (
    <div className={styles.gfiReferrer}>
      <TextInput
        name={options.name}
        initialValue={options.referrer}
        disabled={options.referrer ? true : false}
        placeholder={options.placeholder}
        allCaps={options.allCaps}
        labelWeight={options.labelWeight}
        {...props}
      />
      <div className={styles.gfiReferrerInfoIcon}>
        <Tooltip
          details={options.tooltipText}
          popperBoxStyles={styles.gfiReferrerInfoTooltip}
          placement={'top'}
          softCorners={true}
        >
          <InfoIcon />
        </Tooltip>
      </div>
    </div>
  )
}

const PhoneField = (props: any, options: any = {}): JSX.Element => {
  return (
    <NumberInput
      name={options.name}
      placeholder={options.placeholder}
      mask={options.mask}
      allCaps={options.allCaps}
      labelWeight={options.labelWeight}
      labelCopy={''}
      {...props}
    />
  )
}

const EmailField = (props: any, options: any = {}): JSX.Element => {
  return (
    <EmailInput
      name="agent-form-email-field"
      placeholder={options.placeholder}
      allCaps={options.allCaps}
      labelWeight={options.labelWeight}
      {...props}
    />
  )
}

const AgreeTermsField = (props): JSX.Element => {
  const linkText = (
    <a
      href="/agents/terms-and-conditions/"
      rel="noopener noreferrer"
      onClick={(): void =>
        partnerSignupModuleAnalytics.agreementDocLinkClicked()
      }
      target="_blank"
    >
      Ethos Terms and Conditions
    </a>
  )

  return (
    <CheckboxInput {...props}>
      <div className={styles.agreeterm}>
        I have read and agree to the {linkText}
      </div>
    </CheckboxInput>
  )
}

const AgreeGFITermsField = (props): JSX.Element => {
  const ethosLinkText = (
    <a
      href="/agents/terms-and-conditions/"
      rel="noopener noreferrer"
      target="_blank"
    >
      Ethos Terms and Conditions
    </a>
  )

  const gfiLinkText = (
    <a
      href="https://globalfinancialimpact.com/terms/"
      rel="noopener noreferrer"
      target="_blank"
    >
      GFI Terms and Conditions
    </a>
  )

  return (
    <div className={styles.gfiCheckbox}>
      <CheckboxInput {...props}>
        <div
          className={styles.gfiLabel}
          onClick={(): void =>
            partnerSignupModuleAnalytics.agreementDocLinkClicked()
          }
        >
          I have read and agreed to the {gfiLinkText} and the {ethosLinkText}
        </div>
      </CheckboxInput>
    </div>
  )
}

const AuthorizationField = (props): JSX.Element => {
  const [authorizedInUSA, setAuthorizedInUSA] = useState('')
  return (
    <>
      <div className="font-regular mb-2 text-sm text-gray-3">
        Are you authorized to work in the USA?
      </div>
      <div className={styles.gfiRadioButton}>
        <RadioButtonGroup
          {...props}
          onChange={(value) => setAuthorizedInUSA(value)}
          options={[
            {
              label: (
                <div className="-ml-4 mb-2 whitespace-nowrap text-sm">Yes</div>
              ),
              value: 'yes',
            },
            {
              label: (
                <div className="-ml-4 mb-2 whitespace-nowrap text-sm">No</div>
              ),
              value: 'no',
            },
          ]}
        ></RadioButtonGroup>
      </div>
    </>
  )
}

export const getFields = (
  formType: string,
  gfiReferrerCode: string | undefined
): any => {
  if (formType === 'gfiSignup') {
    return {
      firstName: {
        component: TextField,
        tid: PARTNER_SIGNUP_DATA_TID.FIRST_NAME,
        validators: [validateFirstName],
        labelCopy: 'Legal first name',
        options: {
          allCaps: false,
          labelWeight: 'regular',
          placeholder: 'Enter legal first name',
          name: 'Legal first name',
        },
      },
      lastName: {
        component: TextField,
        tid: PARTNER_SIGNUP_DATA_TID.LAST_NAME,
        validators: [validateLastName],
        labelCopy: 'Legal last name',
        options: {
          allCaps: false,
          labelWeight: 'regular',
          placeholder: 'Enter legal last name',
          name: 'Legal last name',
        },
      },
      email: {
        component: EmailField,
        tid: PARTNER_SIGNUP_DATA_TID.EMAIL,
        validators: [EmailFormatValidator],
        labelCopy: 'Email',
        options: {
          allCaps: false,
          labelWeight: 'regular',
          placeholder: 'Enter email address',
          name: 'Email',
        },
      },
      confirmEmail: {
        component: EmailField,
        tid: PARTNER_SIGNUP_DATA_TID.CONFIRM_EMAIL,
        validators: [EmailFormatValidator],
        labelCopy: 'Re-enter email',
        options: {
          allCaps: false,
          labelWeight: 'regular',
          placeholder: 'Re-enter email address',
          name: 'Re-enter email',
        },
      },
      phone: {
        component: PhoneField,
        tid: PARTNER_SIGNUP_DATA_TID.PHONE,
        validators: [validatePhoneNumber],
        labelCopy: 'Phone number',
        options: {
          allCaps: false,
          labelWeight: 'regular',
          placeholder: 'Enter phone number',
          name: 'Phone number',
          mask: phoneNumberMask,
        },
      },
      referrer: {
        component: TextFieldReferrerCode,
        tid: PARTNER_SIGNUP_DATA_TID.REFERRER,
        validators: [validateReferrerCode],
        labelCopy: 'GFI Referral Code',
        options: {
          allCaps: false,
          labelWeight: 'regular',
          referrer: gfiReferrerCode,
          placeholder: 'Enter your GFI Referral Code',
          name: 'GFI Referral Code',
          tooltipText: 'Ask your referrer for their GFI agent code',
        },
      },
      authorisation: {
        component: AuthorizationField,
        tid: PARTNER_SIGNUP_DATA_TID.AUTHORISATION,
        validators: [validateAuthorized],
        optional: false,
      },
      agreeTerms: {
        component: AgreeGFITermsField,
        tid: PARTNER_SIGNUP_DATA_TID.AGREE_TERMS,
        validators: [validateChecked],
        optional: false,
      },
    }
  } else {
    return {
      agencyName: {
        component: TextField,
        tid: PARTNER_SIGNUP_DATA_TID.AGENCY_NAME,
        validators: [validateAgencyName],
        labelCopy: 'Agency Name',
        options: {
          name: 'Your agency name',
        },
      },
      firstName: {
        component: TextField,
        tid: PARTNER_SIGNUP_DATA_TID.FIRST_NAME,
        validators: [validateValueExists],
        labelCopy: 'First Name',
        options: {
          placeholder: '',
          name: 'Your first name',
        },
      },
      lastName: {
        component: TextField,
        tid: PARTNER_SIGNUP_DATA_TID.LAST_NAME,
        validators: [validateValueExists],
        labelCopy: 'Last Name',
        options: {
          placeholder: '',
          name: 'Your last name',
        },
      },
      email: {
        component: EmailField,
        validators: [EmailFormatValidator],
        tid: PARTNER_SIGNUP_DATA_TID.EMAIL,
        labelCopy: 'Email',
        options: {
          placeholder: '',
          name: 'Your email',
        },
      },
      phone: {
        component: PhoneField,
        tid: PARTNER_SIGNUP_DATA_TID.PHONE,
        validators: [validatePhoneNumber],
        labelCopy: 'Phone number',
        options: {
          placeholder: '',
          name: 'Your phone number',
          mask: phoneNumberMask,
        },
      },
      agreeTerms: {
        component: AgreeTermsField,
        tid: PARTNER_SIGNUP_DATA_TID.AGREE_TERMS,
        validators: [validateChecked],
        optional: false,
      },
    }
  }
}
