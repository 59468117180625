//@ts-nocheck
import React from 'react'

import dayjs from 'dayjs'

import { partnerSignupModuleAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import {
  CreatePartnerParams,
  requestCreatePartner,
} from '../../../../lib/@getethos/api/api'
import {
  PartnerDBEnum,
  SignupForm,
  SignupType,
} from '../../../../lib/@getethos/interfaces/PartnerData'
import { SignupChannelEnum } from '../../../../lib/@getethos/interfaces/SignupChannelEnum'
import { getGrecaptchaToken } from '../lib/useGrecaptcha'
import { PartnerInfo } from '../lib/useSignupInfo'
import AgentForm from './AgentForm'
import { protocol } from './consts'
import { getFields } from './getAgentFormFields'

export const AgentFormManager = ({
  formType,
  signupType,
  signupForm,
  parent,
  referrer,
  grecaptcha,
  siteAgentsUrl,
  siteAgentsApiUrl,
  setError,
  error,
  continueLabel,
}: {
  formType: string
  signupType: SignupType
  signupForm: SignupForm
  parent: PartnerInfo | undefined
  referrer: PartnerInfo | undefined
  grecaptcha: any
  siteAgentsUrl: any
  siteAgentsApiUrl: any
  setError: any
  error: string
  continueLabel: string
}): JSX.Element => {
  const form = 'z-1 max-w-md mx-auto md:mx-0 md:mt-14'

  let gfiReferrerCode
  let urlSearchParams
  try {
    urlSearchParams = new URLSearchParams(window.location.search)
    gfiReferrerCode = urlSearchParams.get('refId') || undefined
  } catch (err) {
    err instanceof Error ? setError(err.message) : setError(err)
  }

  const fields = getFields(formType, gfiReferrerCode)

  const handleSubmit = async (formData): Promise<void> => {
    const isGFI = formType === 'gfiSignup'

    let recaptchaToken
    try {
      recaptchaToken = await getGrecaptchaToken(grecaptcha)
    } catch (error) {
      setError(
        'Verification failed. Please disable any ad blockers and try again.'
      )
      return
    }

    try {
      //Track submitting event
      partnerSignupModuleAnalytics.partnerAgentCreateSubmitting({
        properties: {
          agencyName: isGFI ? 'GFI' : formData.agencyName,
          firstName: formData.firstName,
          lastName: formData.lastName,
          contactEmail: formData.email,
          contactPhoneNo: formData.phone.replace(/\(| |\)|-/g, ''),
          signupChannel: SignupChannelEnum.SelfOnboarding,
        },
      })

      const requestParam: CreatePartnerParams = {
        partner: {
          //if added downline, extract parent id
          //otherwise, assign ethos as parent
          parentId: isGFI ? undefined : parent ? parent.partnerId : 1,
          parentCode: isGFI ? 'c495c' : undefined,
          partnerType: formData.agencyName
            ? PartnerDBEnum.Agency
            : PartnerDBEnum.Agent,
          agencyName: formData.agencyName,
          firstName: formData.firstName,
          lastName: formData.lastName,
          contactEmail: formData.email,
          contactPhoneNo: formData.phone.replace(/\(| |\)|-/g, ''),
          phoneNumbers: [
            {
              type: 'primary',
              number: formData.phone.replace(/\(| |\)|-/g, ''),
            },
          ],
          agreeTermsAt: dayjs().utc().toDate(),
          referredBy: referrer ? referrer.partnerCode : undefined,
          signupType: isGFI ? 'enterprise' : signupType,
          recaptchaToken: recaptchaToken,
          signupChannel: SignupChannelEnum.SelfOnboarding,
          externalRefCode: isGFI ? formData.referrer : undefined,
          isAuthzUsa: isGFI
            ? formData.authorisation === 'yes'
              ? true
              : false
            : undefined,
        },
        form: signupForm,
        lead: {
          utmContent: urlSearchParams.get('utm_content') || undefined,
          utmTerm: urlSearchParams.get('utm_term') || undefined,
          utmSource: urlSearchParams.get('utm_source') || undefined,
          //this is on purpose, we map utm_campaign in url to utmName (which exists as field in salesforce)
          utmName: urlSearchParams.get('utm_campaign') || undefined,
          utmMedium: urlSearchParams.get('utm_medium') || undefined,
          leadUrl: window.location.href,
        },
      }

      const response = await requestCreatePartner(
        requestParam,
        siteAgentsApiUrl
      )

      if (!response.ok) {
        // get error message from body or default to response status
        const data = await response.json()
        const error = (data && data.message) || response.status
        setError(error)
      } else {
        // redirect
        window.location.assign(
          `${protocol(siteAgentsUrl) + siteAgentsUrl}${
            isGFI ? '' : '/quote/create'
          }`
        )
      }
    } catch (err) {
      err instanceof Error ? setError(err.message) : setError(err)
    }
  }

  return (
    <div className={form}>
      <AgentForm
        formType={formType}
        parent={parent}
        referrer={referrer}
        siteAgentsUrl={siteAgentsUrl}
        handleSubmit={handleSubmit}
        fields={fields}
        error={error}
        continueLabel={continueLabel}
      />{' '}
    </div>
  )
}
