/* tslint:disable */
/* eslint-disable */
/**
 * Ethos Partners API Reference
 * Documentation of Ethos Partners API.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  GetSignupInfoResponsePartnerContact,
  GetSignupInfoResponsePartnerContactFromJSON,
  GetSignupInfoResponsePartnerContactFromJSONTyped,
  GetSignupInfoResponsePartnerContactToJSON,
} from './GetSignupInfoResponsePartnerContact'

/**
 *
 * @export
 * @interface GetSignupInfoResponsePartner
 */
export interface GetSignupInfoResponsePartner {
  /**
   *
   * @type {number}
   * @memberof GetSignupInfoResponsePartner
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartner
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartner
   */
  code: string
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartner
   */
  agencyName: string
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartner
   */
  preferredAgencyName: string
  /**
   *
   * @type {GetSignupInfoResponsePartnerContact}
   * @memberof GetSignupInfoResponsePartner
   */
  contact: GetSignupInfoResponsePartnerContact
  /**
   *
   * @type {string}
   * @memberof GetSignupInfoResponsePartner
   */
  accountStatus: string
}

/**
 * Check if a given object implements the GetSignupInfoResponsePartner interface.
 */
export function instanceOfGetSignupInfoResponsePartner(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'id' in value
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'code' in value
  isInstance = isInstance && 'agencyName' in value
  isInstance = isInstance && 'preferredAgencyName' in value
  isInstance = isInstance && 'contact' in value
  isInstance = isInstance && 'accountStatus' in value

  return isInstance
}

export function GetSignupInfoResponsePartnerFromJSON(
  json: any
): GetSignupInfoResponsePartner {
  return GetSignupInfoResponsePartnerFromJSONTyped(json, false)
}

export function GetSignupInfoResponsePartnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSignupInfoResponsePartner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    type: json['type'],
    code: json['code'],
    agencyName: json['agency_name'],
    preferredAgencyName: json['preferred_agency_name'],
    contact: GetSignupInfoResponsePartnerContactFromJSON(json['contact']),
    accountStatus: json['account_status'],
  }
}

export function GetSignupInfoResponsePartnerToJSON(
  value?: GetSignupInfoResponsePartner | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    type: value.type,
    code: value.code,
    agency_name: value.agencyName,
    preferred_agency_name: value.preferredAgencyName,
    contact: GetSignupInfoResponsePartnerContactToJSON(value.contact),
    account_status: value.accountStatus,
  }
}
