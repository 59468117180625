const globalScriptPromises: Record<string, Promise<void>> = {}

export function useGlobalScript(url: string): Promise<void> {
  if (typeof document !== 'undefined') {
    if (!globalScriptPromises[url]) {
      const scriptElement = document.createElement('script')
      scriptElement.setAttribute('src', url)
      scriptElement.setAttribute('async', 'true')
      const promise = new Promise<void>((resolve, reject) => {
        scriptElement.onload = (): void => resolve()
        scriptElement.onerror = reject
      })
      globalScriptPromises[url] = promise
      document.body.appendChild(scriptElement)
    }
  }
  return globalScriptPromises[url]
}
